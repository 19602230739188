import { Comprobante } from './../../models/comprobante.models';
import { Cliente } from 'app/models/cliente.models';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Moneda } from 'app/models/moneda.models';
import { Producto } from 'app/models/producto.models';
import { AlicuotaivaService } from 'app/services/alicuotaiva.service';
import { ParametrosService } from 'app/services/parametros.service';
import { UmedidaService } from 'app/services/umedida.service';
import { MatDialog } from '@angular/material/dialog';
import { ListaClienteComponent } from './lista-cliente/lista-cliente.component';
import { Cabecera } from 'app/models/cabecera.models';
import { Detalle } from 'app/models/detalle.models';
import { ComprobantesService } from 'app/services/comprobantes.service';
import { ListaProductosDialogoComponent } from '../lista-productos-dialogo/lista-productos-dialogo.component';
import { CajaService } from 'app/services/caja.service';
import * as dayjs from 'dayjs';
import { DataService } from 'app/services/data.service';

@Component({
  selector: 'app-comprobante',
  templateUrl: './comprobante.component.html',
  styleUrls: ['./comprobante.component.css']
})
export class ComprobanteComponent implements OnInit, OnDestroy {
  @ViewChild('cantidad') cantidadTabla: ElementRef;
  conceptos = [];
  otrostributos = [];
  tipoivas = [];
  monedas: Moneda[] = [];
  tipoComprobantes = [];
  form: UntypedFormGroup;
  cliente: Cliente;
  textoBtn: string;
  subtotal = 0;
  total = 0;
  bonificacion = 0;
  impuestos = 0;
  comprobante: Comprobante;
  cabecera: Cabecera;
  bonif: number;
  fecha = new UntypedFormControl(new Date());
  fechaDesde = new UntypedFormControl(new Date());
  fechaHasta = new UntypedFormControl(new Date());
  verFechasDesdeHasta: boolean;

  sidebar: HTMLElement;
  tipoComprobanteDB;
  nro: number;
  punto: number;
  tipo: string;
  operacion: string;
  tipoComprobante: string;
  tipoCliente;

  constructor(
    private fb: UntypedFormBuilder,
    public cajaService: CajaService,
    private _snackBar: MatSnackBar,
    private parametroServicio: ParametrosService,
    public dialog: MatDialog,
    private routerActive: ActivatedRoute,
    private router: Router,
    private comprobanteSerice: ComprobantesService,
    private dataService: DataService
  ) {
    this.comprobante = new Comprobante();
    this.cabecera = new Cabecera();
    this.tipoComprobante = this.routerActive.snapshot.params.tipocomprobante;
    this.tipo = this.routerActive.snapshot.params.tipo;
    this.operacion = this.routerActive.snapshot.params.operacion;
    if (this.tipo === 'ventas') {
      this.tipoCliente = 'Cliente'
    } else {
      this.tipoCliente = 'Proveedor'
    }
    this.form = this.fb.group({
      talonario: [1],
      numero: [1],
      cliente: [''],
      fecha: [''],
      condiventa: ['contado'],
      codigobarra: [''],
      concepto: [''],
      impuestos: [''],
      comentario: [''],
      iva: [''],
      moneda: [''],
      posicioniva: ['grabado'],
      listaprecio: ['']
    });
    if (this.operacion === 'create') {
      this.textoBtn = 'Crear';
    } else {
      this.textoBtn = 'Actualizar';
      console.log(this.tipoComprobante)
      // al editar un comprobante el _id se encuentra en tipoComprobante
      this.comprobanteSerice.getComprobanteById(this.tipoComprobante).subscribe(
        res => {
          this.tipoComprobante = res.tipocomprobante.nombre;
          this.setDatosComprobante(res);
        },
        err => {
          console.log(err)
          this._snackBar.open('Error al cargar el comprobante', 'Cerrar', { duration: 3000 });
        }
      )

    }

    this.form.controls.concepto.valueChanges.subscribe(r => {
      if (r === 'Servicios' || r === 'Productos y Servicios') {
        this.verFechasDesdeHasta = true;
      } else {
        this.verFechasDesdeHasta = false;
      }
    })

    this.form.controls.moneda.disable();
  }

  ngOnDestroy(): void {
    this.cajaService.articulosList = [];
  }

  ngOnInit(): void {
    this.sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (this.sidebar !== undefined) {
      this.sidebar.innerHTML = 'Comprobante';
    }
    this.getParametros();
  }

  getParametros() {
    this.parametroServicio.getParametrosComprobantes().subscribe(
      data => {
        this.monedas = data.monedas;
        this.tipoivas = data.alicuotasiva;
        this.conceptos = data.conceptos;
        this.otrostributos = data.tributos;

        let indiceOtros = this.conceptos.findIndex(x => x.Desc.toLowerCase().includes('otros'));
        this.conceptos.splice(indiceOtros, 1);
        let concepto = this.conceptos.find(x => x.Id === '1');
        this.form.controls.concepto.setValue(concepto?.Desc, {
          onlySelf: true
        })
        let tipo = this.tipoivas.find(x => x.codigo === '5')
        this.form.controls.iva.setValue(tipo?._id, {
          onlySelf: true
        })
        let moneda = this.monedas.find(x => x.codigo === 'PES')
        this.form.controls.moneda.setValue(moneda?._id, {
          onlySelf: true
        })

        this.tipoComprobantes = data.tipocomprobantes;
        switch (this.tipoComprobante) {
          case 'presupueto':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '903');
            break;
          case 'remito':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '091');
            break;
          case 'recibo':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '004');
            break;
          case 'factura':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '001');
            break;
          case 'notadecredito':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '003');
            break;
          case 'notadedebito':
            this.tipoComprobanteDB = this.tipoComprobantes.find(x => x.codigo === '002');
            break;
        }
        if (this.operacion === 'create') {
          this.getNumeroComprobante();
        }
      },
      err => {
        console.log(err);
      }
    )
  }

  setDatosComprobante(datos: Comprobante) {
    this.form.patchValue(datos);
    this.form.controls.cliente.setValue(datos.cliente.razonsocial);
    this.fecha.setValue(dayjs(datos.cabecera.fechadesde).toDate());
    this.fechaDesde.setValue(dayjs(datos.cabecera.fechadesde).toDate());
    this.fechaHasta.setValue(dayjs(datos.cabecera.fechahasta).toDate());
    this.cajaService.articulosList = Array.from(datos.detalle);
    this.subtotal = datos.impneto;
    this.total = datos.imptotal;
    this.cliente = datos.cliente;
    this.form.controls.concepto.setValue(datos.concepto);
    if (this.sidebar !== undefined) {
      const punto = this.dataService.formatearPuntoVenta(datos.cabecera.puntoventa);
      const nro = this.dataService.formatearNumeroComprobante(datos.cabecera.numero);
      this.sidebar.innerHTML = `${this.tipoComprobante}: ${punto} - ${nro}`;
    }
  }

  getNumeroComprobante() {
    this.comprobanteSerice.getComprobanteByTipoNumero(this.tipoComprobanteDB._id).subscribe(
      res => {
        if (this.sidebar !== undefined) {
          let punto = this.dataService.formatearPuntoVenta(res.pventa);
          let nro = this.dataService.formatearNumeroComprobante(res.numero);
          this.sidebar.innerHTML = `${this.tipoComprobanteDB.nombre}: ${punto} - ${nro}`;
          this.punto = res.pventa;
          this.nro = res.numero;
        }
      },
      err => {
        console.log(err)
      }
    )
  }

  openDialogClientes() {
    const dialogRef = this.dialog.open(ListaClienteComponent, {
      //minWidth: '50%',
      minHeight: '50%',
      data: {
        tipo: this.tipoCliente
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cliente = result;
        this.form.controls.cliente.setValue(this.cliente.razonsocial, {
          onlySelf: true
        })
      }
    });
  }

  salir() {
    if (this.tipo === 'ventas') {
      this.router.navigate(['/ventas']);
    } else {
      this.router.navigate(['/compras']);
    }
  }

  verListaArticulos() {
    const dialogRef = this.dialog.open(ListaProductosDialogoComponent, {
      minWidth: '60%',
      maxHeight: '85%',
      minHeight: '300px',
      data: {
        tipo: 'articulo',
        bonificacion: this.cliente?.bonificacion,
      },
      disableClose: true,
      autoFocus: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.sumarTotalProductos();
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  sumarTotalProductos() {
    this.total = 0;
    this.subtotal = 0;
    this.bonificacion = 0;
    this.cajaService.articulosList.forEach(p => {
      const subtotal: number = (p.cantidad * p.preciounitario);
      const bonif = (subtotal * p.bonificacion) / 100;
      const total: number = subtotal - bonif;
      this.subtotal += subtotal;
      this.bonificacion += bonif;
      this.total += total;
    });
  }

  comprobarInput(tipo, valor, producto) {
    let isError: boolean;
    let maxStock = 0;
    if (valor >= 0) {
      const productoTabla = this.cajaService.articulosList.find(x => x._id === producto._id)
      const productoTablaIndex = this.cajaService.articulosList.findIndex(x => x._id === producto._id)
      switch (tipo) {
        case 'cantidad':
          if (productoTabla.cantidad !== valor) {
            if (productoTabla.stock.length > 0) {
              let stock = productoTabla.stock[0];
              if (stock) {
                maxStock = stock.cantidad;
                if (stock.cantidad < valor) {
                  productoTabla.cantidad = maxStock;
                  let id = document.getElementById(`${productoTabla.codigo}`) as HTMLInputElement;
                  id.value = maxStock.toString();
                  this.openSnackBar(`La cantidad ingresada supera el máximo en stock (${stock.cantidad})`, 'Cerrar');
                  return;
                } else {
                  productoTabla.cantidad = valor;
                }
              }
            }
          }
          break;
        case 'importe':
          if (productoTabla.preciounitario !== valor) { productoTabla.preciounitario = valor; }
          break;
        case 'bonif':
          if (productoTabla.bonificacion !== valor) { productoTabla.bonificacion = valor; }
          break;
      }
      let subtotal: number = (productoTabla.cantidad * productoTabla.preciounitario);
      let bonif = 0
      if (productoTabla.bonificacion) {
        bonif = (subtotal * productoTabla.bonificacion) / 100;
      }
      let total: number = subtotal - bonif;
      productoTabla.subtotal = total;
      productoTabla.subtotalciva = total;
      this.cajaService.articulosList[productoTablaIndex] = productoTabla;
      this.sumarTotalProductos();
    }
  }

  calcularIvaVenta(precio) {
    const precioSinIva = this.calcularPrecioSinIva(precio);
    this.form.controls.preciosinivaventa.setValue(precioSinIva, {
      onlySelf: true
    });
  }

  calcularIvaCompra(precio) {
    const precioSinIva = this.calcularPrecioSinIva(precio);
    this.form.controls.preciosinivacompra.setValue(precioSinIva, {
      onlySelf: true
    });
  }

  calcularPrecioSinIva(precio) {
    let precioSinIva = 0;
    const iva: any = this.tipoivas.find(x => x._id === this.form.value.iva);
    if (iva) {
      const ivaValor = (iva.nombre.includes('%')) ? iva.nombre.replace('%', '').trim() : '0';
      const convertimosIva = Number.parseFloat(ivaValor.toString());
      const precioIva = (precio * convertimosIva) / 100;
      precioSinIva = (precio - precioIva);
    }
    return precioSinIva;
  }

  eliminarProducto(producto) {
    const productoTablaIndex = this.cajaService.articulosList.findIndex(x => x._id === producto._id);
    this.cajaService.articulosList.splice(productoTablaIndex, 1);
    this.sumarTotalProductos();
  }

  guardarPresupuesto() {
    // Cabecera
    let fecha = dayjs(this.fecha.value).format('DD/MM/YYYY');
    let fechaDesde = dayjs(this.fechaDesde.value).format('DD/MM/YYYY');
    let fechaHasta = dayjs(this.fechaHasta.value).format('DD/MM/YYYY');
    this.cabecera.puntoventa = this.punto;
    this.cabecera.numero = this.nro;
    this.cabecera.condicionventa = this.form.controls.condiventa.value;
    this.cabecera.cuitemisor = this.cliente.nrodocumento ? this.cliente.nrodocumento : '304586956692';
    this.cabecera.domicilio = this.cabecera.domicilio ? this.cabecera.domicilio : 'mitre 144';
    this.cabecera.fecha = fecha;
    this.cabecera.fechadesde = fechaDesde;
    this.cabecera.fechahasta = fechaHasta;
    this.cabecera.fechavencimiento = fechaDesde;
    this.cabecera.ingresosbrutos = this.cabecera.ingresosbrutos ? this.cabecera.ingresosbrutos : '304586956692';
    this.cabecera.inicioactividad = this.cabecera.inicioactividad ? this.cabecera.inicioactividad : '20/02/1990';
    this.cabecera.razonsocial = this.cabecera.razonsocial ? this.cabecera.razonsocial : 'Kocursoft';
    // Comprobante
    this.comprobante.cantreg = this.cajaService.articulosList.length;
    this.comprobante.cabecera = this.cabecera;
    this.comprobante.cae = '';
    this.comprobante.codigobarras = '';
    this.comprobante.comentario = this.form.controls.comentario.value;
    this.comprobante.compasociado = [];
    let concepto = this.conceptos.find(x => x.Desc === this.form.controls.concepto.value);
    this.comprobante.concepto = concepto._id;
    this.comprobante.moneda = this.form.controls.moneda.value;
    this.comprobante.cliente = this.cliente;
    this.comprobante.electronico = false;
    this.comprobante.enviado = false;
    this.comprobante.estado = 'creado';
    this.comprobante.tipo = this.tipo;
    this.comprobante.fechavtocae = '';
    this.comprobante.impiva = 0;
    this.comprobante.impneto = this.subtotal;
    this.comprobante.impopext = 0;
    this.comprobante.imptotconc = 0;
    this.comprobante.imptrib = 0;
    this.comprobante.imptotal = this.total;
    this.comprobante.moncotiz = 0;
    this.comprobante.comentario = this.form.controls.comentario.value;
    this.comprobante.tipocomprobante = this.tipoComprobanteDB.nombre;

    // Detalle
    this.comprobante.detalle = this.cajaService.articulosList;
    if (this.comprobante.cantreg > 0) {
      this.comprobanteSerice.guardarComprobante(this.comprobante, true).subscribe(
        (data) => {
          this.salir();
          this.openSnackBar(`Comprobante creado correctamente`, 'Información');
        },
        (err) => {
          console.log(err);
          this.openSnackBar(`Error al crear comprobante`, 'Información');
        }
      )
    }

  }

}
