import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ClientesService } from "app/services/clientes.service";

@Component({
  selector: "app-lista-cliente-caja",
  templateUrl: "./lista-cliente-caja.component.html",
  styleUrls: ["./lista-cliente-caja.component.css"],
})
export class ListaClienteCajaComponent implements OnInit {
  @ViewChild(MatPaginator)
  set paginat(paginator: MatPaginator) {
    this.paginator = paginator;
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private paginator: MatPaginator;
  verSpinner: boolean;
  verPaginator: boolean;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ["numero", "razonsocial", "nrodocumento"];
  paginaSiguiente = 1;
  itemXPag = 10;
  search = '';
  pageEvent;
  length = 0;
  pageSize = 10;
  lastKeypress: any;
  pageIndex = 0;
  clientes = [];

  constructor(
    private clienteService: ClientesService,
    public dialogRef: MatDialogRef<ListaClienteCajaComponent>,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getClientes();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getClientes() {
    this.clientes = [];
    this.dataSource.data = [];
    this.verSpinner = true;
    this.clienteService.getClientes(this.paginaSiguiente, this.itemXPag, this.search).subscribe(
      (res) => {
        this.clientes = res.data;
        this.dataSource = new MatTableDataSource(res.data);
        this.dataSource.paginator = this.paginator;
        this.length = res.total;
        this.verSpinner = false;
      },
      err => {
        this.openSnackBar(`Ocurrió un error al obtener listado clientes`, 'Información');
        this.verSpinner = false;
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.search = filterValue.trim().toLowerCase();
    if (event.timeStamp - this.lastKeypress > 100) {
      this.paginaSiguiente = 1;
      this.getClientes();
      this.dataSource.paginator.firstPage();
    }
    this.lastKeypress = event.timeStamp;
  }

  handlePageEvent(e: PageEvent) {
    this.paginaSiguiente += 1;
    this.getClientes();
  }

  cancelar() {
    this.dialogRef.close();
  }

  selCliente(cliente) {
    this.dialogRef.close(cliente);
  }
}
