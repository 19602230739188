import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Producto } from 'app/models/producto.models';
import { Venta } from 'app/models/venta.models';
import { VentasService } from 'app/services/ventas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['numero', 'fecha', 'cliente', 'tipo','cuit', 'enviado','electronico', 'total','accion'];
  dataSource: MatTableDataSource<Venta> = new MatTableDataSource();

  loading: boolean;
  ventas: Venta[] = [];
  date = new UntypedFormControl();
  promedio = 0;
  totalVentas = 0;
  totalFacturas = 0;
  totalComprobantes = 0;

  constructor(
    private ventasService: VentasService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const sidebar = <HTMLElement>document.querySelector('.navbar-brand');
    if (sidebar !== undefined) {
      sidebar.innerHTML = 'Ventas';
    }
    this.getVentas();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getVentas() {
    this.loading = true;
    this.dataSource.data = [];
    this.ventasService.getVentas().subscribe((data) => {
      this.ventas = data.ventas;
      this.promedio = data.dashboard.promedio;
      this.totalVentas = data.dashboard.totalVentas;
      this.totalFacturas = data.dashboard.totalFacturas;
      this.totalComprobantes = data.dashboard.totalComprobantes;
      this.dataSource.data = this.ventas;
      this.dataSource.paginator = this.paginator;
      this.loading = false;
    },
    err => {
      this.loading = false;
    })
  }

  openDialog(producto?: Producto) {
    this.router.navigate(['/productos','create']);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  eliminar(comprobante) {
    Swal.fire({
      title: `¿Desea eliminar el comprobante seleccionado?`,
      text: `Eliminar Comprobante`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalo!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ventasService.eliminarVenta(comprobante._id).subscribe(
          (data) => {
            this.ventas = data;
            this.getVentas();
            this.openSnackBar(`${comprobante.tipocomprobante.nombre } eliminado correctamente`, 'Información');
          },
          (err) => {
            console.log(err);
            this.openSnackBar(`Error al eliminar el ${comprobante.tipocomprobante.nombre }`, 'Información');
          }
        );
      }
    })
  }

}
